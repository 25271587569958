<template>
  <div>
    <div class="row">
        <div class="col-md-12">
          <h2 style="border-bottom: 1px solid rgba(34, 42, 66, 0.2); padding-bottom: .5em;">Regulamento</h2>
        </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6 ">
        <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1046565202?badge=0&amp;autopause=0&amp;loop=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="VIDEOS GURUNEWS_1080x1920 (ADPTAÇÃO)_CAPCUT"></iframe></div>
      </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6 ">
          <h5>Premiação:</h5>
          <table class="table">
            <thead>
              <tr>
                <th>Pontuação</th>
                <th>Premiação</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>3.000 gurus ou mais</td>
                <td>R$ 1.000,00</td>
              </tr>
              <tr>
                <td>Entre 2.500 e 2.999 gurus</td>
                <td>R$ 300,00</td>
              </tr>
              <tr>
                <td>Entre 2.000 e 2.499 gurus</td>
                <td>R$ 50,00</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  
    <div class="row mt-3">
      <div class="col-md-12">
        <h5>Quem pode participar?</h5>
        <ul>
          <li>É obrigatório ter mais de 18 anos para participar e para receber a premiação.</li>
          <li>Só é permitido a cada pessoa participar usando apenas 1 número de Whatsapp. </li>
          <li>É necessário ter realizado o cadastro no gurubets com o mesmo número de telefone 
            utilizado no grupo do gurunews e ter finalizado o cadastro antes do início do desafio da semana. 
            <br/>Realize o seu cadastro pelo link: 
            <a href="https://convidadoguru.com/vimpelogurunews" style="font-weight: bold;" target="_blank">convidadoguru.com/vimpelogurunews</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-3">    
      <div class="col-md-12">
        <h5>Como acumular gurus e ganhar a premiação?</h5>
        <ul>
          <li>Cada desafio começa na segunda-feira e termina com os resultados do domingo.</li>
          <li>A pontuação total do desafio para cada participante será a soma dos acertos ou pontuação por participação (quando for o caso) de cada participante no período do desafio.</li>
          <li>Cada alternativa de resposta tem uma pontuação específica e informada previamente na divulgação de cada desafio diário.</li>
          <li>Caso nenhuma alternativa do desafio diário seja considerada correta, todos os participantes que votaram receberão 100 pontos. Os que não votaram não farão jus aos 100 pontos extras.</li>
          <li>Só serão considerados os votos dentro dos horários especificados na mensagem de divulgação do desafio diário.</li>
        </ul>
      </div>
    </div>
    <div class="row mt-3">    
      <div class="col-md-12">
        <h5>Regras Gerais:</h5>
        <ul>
          <li>Não é uma competição, a premiação é definida pela pontuação individual de cada participante.</li>
          <li>Caso tenha alguma instabilidade nas informações das enquetes enviadas pelo Whatsapp, nos reservamos o direito de suspender a votação do dia em questão.</li>
          <li>A premiação máxima para todos os participantes é de R$ 5.000,00. Caso a soma total da premiação semanal ultrapasse esse valor, os prêmios serão distribuídos proporcionalmente entre todos os participantes, respeitando a pontuação de cada participante e o valor máximo de premiação máxima.</li>
          <li>A premiação será paga via PIX em até 48hs - período para conferência de que todas as regras foram respeitadas.</li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script src="https://player.vimeo.com/api/player.js"></script>
<script>
  import config from '@/config';
  

  // const MONTHS = [
  //   'January', 'February', 'March', 'April', 'May', 'June',
  //   'July', 'August', 'September', 'October', 'November', 'December'
  // ]


  export default {
    data() {
      return {
        columns: [
          { label: 'Id', field: 'id'},
          { label: 'Título', field: 'titulo'},
          { label: 'Texto', field: 'texto'},
          { label: 'Imagem', field: 'imagem'},
          // { label: 'Status', field: 'status'},
          { label: 'Publicação', field: 'datahora_publicacao'},
          // { label: 'Usuário', field: 'usuario_publicacao'},
        ],
        userdata: {},
        loading: true,
        ranking: [],
        searchOptions: {
              enabled: true,
              placeholder: 'Buscar...',
        },
      }
    },
    methods: {
      nova() {
        this.$router.push('/noticias/novo')
      },
      getPhoneExibir(phone) {
        let retorno = phone
        if (phone.search('BR') >= 0) {
          retorno = retorno.replace("BR", this.getFlag("BR"))
          let estado = phone.substring(2,4)
          retorno = retorno.replace(estado, this.getFlag(estado))
        } else {
          retorno = this.getFlag("IN") + retorno
        }
        return retorno
      },
      getFlag(flag) {
        if (flag.length) {
          return '<img src="/img/flags/'+ flag +'.png" alt="'+flag+'"> '
        } else {
          return ''
        }
      },

      // showPopUpDelete(id) {
      //   // this.$swal("Ops!", "Ocorreu algum erro inesperado.", "error");
      //   this.$swal({
      //       title: "Deletar Notícia",
      //       text: "Tem certeza que deseja deletar a notícia '"+id+"'?",
      //       type: "warning",
      //       showCancelButton: true,
      //       cancelButtonText: "Cancelar",
      //       confirmButtonColor: "#3085d6",
      //       confirmButtonText: "Sim, Excluir!"
      //   }).then((result) => { // <--
      //       if (result.value) { // <-- if confirmed
      //         this.$aws
      //         .delete('noticias/'+id)
      //         .then(response => {
      //           if (response.status == 200) {
      //             this.$swal("Ok!", "Notícia Excluída.", "success");
      //           } else {
      //             this.$swal("Ops!", "Ocorreu algum erro inesperado. (erro"+ response.status +")", "error");
      //           }
      //           this.$router.push('/noticias/#'+id)
      //         })
      //         .catch(function (error) {
      //               console.error(error)
      //         });   
      //       }
      //   });
      // },
      // edit(id) {
      //   this.$router.push('noticias/edit/'+id)
      // },
      // getUsuario() {
      //   try {
      //     let user = null
      //     if (typeof localStorage.ADMIN_AUTH_USER !== "undefined") {
      //       user = JSON.parse(localStorage.ADMIN_AUTH_USER)
      //     }
      //     return user
      //   } catch (error) {
      //     console.error(error)
      //     localStorage.ADMIN_AUTH_USER = null
      //   }
      // },
    },
    mounted() {
      this.$aws
      .get('whatsapp/campanha/resultado')
      .then(response => {
        this.ranking = JSON.parse(JSON.stringify(response.data))
      })
      .catch(function (error) {
        console.error(error)
      });   
      // let user = this.getUsuario()
      // if (user && user.id) {
      //   this.$aws
      //   .get('noticias')
      //   .then(response => {
      //     this.userdata = JSON.parse(JSON.stringify(response.data))

      //     $(document).ready( function () {
      //       $('#tbl-guru').DataTable({
      //         ordering: false,
      //         lengthMenu: [50, 100, 300, 500],
      //         searching: true,
      //         "language": {
      //           "emptyTable": "Nenhum registro disponível na tabela",
      //           "info":           "Mostrando _START_ até _END_ de _TOTAL_ registros",
      //           "infoEmpty":      "Mostrando 0 até 0 de 0 registros",
      //           "infoFiltered":   "(filtrado de um total de _MAX_ registros)",
      //           "lengthMenu":     "Mostrar _MENU_ registros",
      //           "loadingRecords": "Carregando...",
      //           "search":         "Busca:",
      //           "zeroRecords":    "Nenhum registro encontrado.",
      //           "paginate": {
      //               "first":      "Primeiro",
      //               "last":       "Último",
      //               "next":       "Próximo",
      //               "previous":   "Anterior"
      //           }
      //         },
      //       });
      //     });
      //     this.loading = false
      //   })
      //   .catch(function (error) {
      //     console.error(error)
      //   });   
      // }
    },
  };
</script>
<style lang="scss">
  h3 {
    margin: 0px;
  }
  .table {
    // direction: ltr;
    overflow-x: hidden;
  }
  .card-body {
    height: 40em; 
    width: 100%;
    overflow-x: hidden;
  }
  h5 {
    font-weight: bold;
    font-size: 1.3em;
  }
  // .table-responsive {
  //   scroll
  // }
  /*
  .dataTables_wrapper {
    margin-top: 20px;
    tbody td:last-child {
      text-align: center;
    }
  }
*/
  /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --g: #0000 calc(100% - 5px),#FC7D59 calc(100% - 3px) 93%,#0000;
  background: 
    radial-gradient(farthest-side at bottom,var(--g)) top                  /100% 50%,
    radial-gradient(farthest-side at top   ,var(--g)) bottom 18% right  0  /80%  40%,
    radial-gradient(farthest-side at bottom,var(--g)) left   50% top    30%/60%  30%,
    radial-gradient(farthest-side at top   ,var(--g)) right  34% bottom 37%/40%  20%,
    radial-gradient(farthest-side at bottom,var(--g)) left   52% top    43%/24%  12%;
  background-repeat: no-repeat;
  animation: l20 1s infinite linear;
}
/*
@keyframes l20 {
  100% {transform: rotate(1turn)}
}
  */
</style>
