import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";


const Desafio = () => import(/* webpackChunkName: "dashboard" */"@/pages/Pontuacao.vue");
const Regulamento = () => import(/* webpackChunkName: "dashboard" */"@/pages/Regulamento.vue");
const Home = () => import(/* webpackChunkName: "dashboard" */"@/pages/Home.vue");

const routes = [
  {
    path: "/pesquisa",
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://pvxq1h7esry.typeform.com/to/hXqXwN0u")
    }
  },
  {
    path: "/gurubets",
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      // window.location.replace("https://gurubets.net/")
      window.location.replace("https://gurupartners.vip/redirect?referralCode=53026514")
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  // {
  //   path: "/desafio",
  //   name: "Desafio",
  //   component: DashboardLayout,
  //   component: Desafio
  // },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/desafio",
    children: [
      {
        path: "Desafio",
        name: "Desafio",
        component: Desafio
      },
      {
        path: "regulamento",
        name: "Regulamento",
        component: Regulamento
      },
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
